<template>
  <div class="article">
    <transition name="slide-fade-1">
      <div
        class="tbl_wrap"
        key="tbl"
        v-if="selectedIndex == -1 && selectedIndex != -2"
      >
        <div class="tbl_option">
          <div>
            <span>조회기간</span>
            <div>
              <div class="input_text">
                <input
                  type="date"
                  placeholder="YYYY-MM-DD"
                  :value="start_date"
                  @change="setStartDate($event)"
                />
              </div>
              <p>~</p>
              <div class="input_text">
                <input
                  type="date"
                  placeholder="YYYY-MM-DD"
                  :value="end_date"
                  @change="setEndDate($event)"
                />
              </div>
            </div>
          </div>
          <div class="input_text">
            <span class="option_txt">거래처 구분</span>
            <div>
              <my-local-selectric
                :id="'search_type_selectric'"
                :watch="searchType"
                :options="company_options_all"
                @changeValue="changeSelectric($event)"
              >
              </my-local-selectric>
            </div>
          </div>
          <div class="input_search">
            <span>거래처명</span>
            <input
              type="text"
              placeholder="거래처 검색"
              :value="getCompanyInfo(companyTypeId).name"
              disabled
            />
            <button>
              <i
                class="fa fa-search"
                @click="
                  searchType == null
                    ? openOneButtonModal(
                        '거래처 구분 선택',
                        '거래처 구분을 선택 해주세요',
                      )
                    : (showCompanySearch = true)
                "
              ></i>
            </button>
          </div>
          <button type="button" class="btn_sub1" @click="SelectRow(-2)">
            등록
          </button>
          <!-- :class="{ on: managementMode }" -->
        </div>
        <h6>조회수 : {{ filteredQuotation.length }} 건</h6>
        <div class="mes_tbl_wrap">
          <table class="mes_tbl">
            <thead>
              <tr>
                <th>견적일자</th>
                <th>거래처 구분</th>
                <th>거래처명</th>
                <th>견적항목</th>
                <th>견적금액(VAT)</th>
                <th v-show="managementMode">삭제</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in filteredQuotation"
                :key="item.id"
                @click="SelectRow(index)"
                :class="selectedIndex == index ? 'active' : ''"
              >
                <td>{{ item.quotation_date }}</td>
                <td>
                  {{ item.purchase_type_id != null ? '구매처' : '매출처' }}
                </td>
                <td>{{ findInfoFromId(companys, item.company_id).name }}</td>
                <td>
                  {{
                    item.purchase_type_id != null
                      ? getFirstPurchaseMaterial(index)
                      : getFirstSalesProduct(index)
                  }}
                </td>
                <td>
                  ₩{{
                    ` ${$makeComma(item.quotation_value)} (VAT${
                      findInfoFromId(companys, item.company_id).vat == 1
                        ? '포함'
                        : '미포함'
                    })`
                  }}
                </td>
                <td v-show="managementMode" @click="ShowModal(index)">
                  <button class="tbl_delete_btn">
                    delete
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </transition>
    <transition name="slide-fade">
      <div class="aside_management_mode" key="aside" v-if="selectedIndex != -1">
        <div class="head">
          <button type="button" class="btn_prev" @click="SelectRow(-1)">
            <h5>견적서 조회</h5>
          </button>
        </div>
        <div class="aside_cont">
          <div class="aside_option">
            <div :class="selectedIndex == -2 ? 'input_search' : 'input_text'">
              <span>거래처명</span>
              <input
                type="text"
                placeholder="거래처 검색"
                :value="getCompanyInfo(managementData.company_id).name"
                :readonly="selectedIndex == -2 ? false : true"
              />
              <button v-show="selectedIndex == -2">
                <i
                  class="fa fa-search"
                  @click="
                    searchType == null
                      ? openOneButtonModal(
                          '거래처 구분 선택',
                          '거래처 구분을 선택 해주세요',
                        )
                      : (showCompanySearch = true)
                  "
                ></i>
              </button>
            </div>
            <div class="input_text">
              <span class="option_txt">거래처 구분</span>
              <my-local-selectric
                v-if="selectedIndex == -2"
                :id="'search_type_selectric'"
                :watch="searchType"
                :options="companyOption"
                @changeValue="changeSelectric($event)"
                :readonly="selectedIndex == -2 ? false : true"
              >
              </my-local-selectric>
              <input
                v-else
                type="text"
                placeholder="구분"
                :value="
                  managementData.purchase_type_id != null ? '구매처' : '매출처'
                "
                :readonly="selectedIndex == -2 ? false : true"
              />
            </div>
            <div class="input_text">
              <span>견적일</span>
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="managementData.quotation_date"
                :readonly="selectedIndex == -2 ? false : true"
              />
            </div>
            <button
              type="button"
              class="excel_btn"
              v-show="!managementMode && selectedIndex != -2"
              @click="exportExcel"
            >
              엑셀로 저장
            </button>
          </div>
          <div class="mes_tbl_wrap">
            <table class="mes_tbl">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>항목</th>
                  <th>비고</th>
                  <th>과세여부</th>
                  <th>단위</th>
                  <th>수량</th>
                  <th>단가</th>
                  <th>금액</th>
                  <th v-if="managementMode">삭제</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in managementData.purchase_type_id !=
                  null
                    ? managementData.quotation_material_list
                    : managementData.quotation_product_list"
                  :key="index.id"
                >
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{ changeMaterialProduct(item).name }}
                  </td>
                  <td>
                    <input
                      v-show="managementMode || selectedIndex == -2"
                      type="text"
                      :value="item.description"
                      @input="item.description = $event.target.value"
                    />
                    {{
                      !managementMode && selectedIndex != -2
                        ? item.description
                        : ''
                    }}
                  </td>
                  <td>
                    {{ changeMaterialProduct(item).tax ? '과세' : '면세' }}
                  </td>
                  <td>
                    {{ changeMaterialProduct(item).unit_name }}
                  </td>
                  <td>
                    <input
                      v-show="managementMode || selectedIndex == -2"
                      type="text"
                      :value="$makeComma(item.quantity)"
                      inputmode="decimal"
                      @input="
                        $inputNumber($event, item, 'quantity');
                        item.total_cost = changeMaterialProduct(item).total;
                      "
                    />
                    {{
                      !managementMode && selectedIndex != -2
                        ? $makeComma(item.quantity)
                        : ''
                    }}
                  </td>
                  <td>
                    <input
                      v-show="managementMode || selectedIndex == -2"
                      type="text"
                      :value="$makeComma(item.cost)"
                      @input="
                        $inputNumber($event, item, 'cost');
                        item.total_cost = changeMaterialProduct(item).total;
                      "
                    />
                    {{
                      !managementMode && selectedIndex != -2
                        ? $makeComma(item.cost)
                        : ''
                    }}
                  </td>
                  <td>
                    <input
                      v-show="managementMode || selectedIndex == -2"
                      type="text"
                      :value="$makeComma(changeMaterialProduct(item).total)"
                      @blur="
                        $event => {
                          $event.target.value = $makeComma(
                            changeMaterialProduct(item).total,
                          );
                        }
                      "
                      @keydown.enter="
                        $event => reCalCost(item, $event.target.value)
                      "
                      @keypress="onlyNumber($event)"
                      @input="$inputNumberIntNoTarget($event)"
                    />
                    {{
                      !managementMode && selectedIndex != -2
                        ? $makeComma(item.total_cost)
                        : ''
                    }}
                  </td>
                  <td v-show="managementMode">
                    <button
                      v-if="
                        managementData.quotation_material_list.length > 0 ||
                          managementData.quotation_product_list.length > 0
                      "
                      @click="
                        managementData.purchase_type_id != null
                          ? managementData.quotation_material_list.splice(
                              index,
                              1,
                            )
                          : managementData.quotation_product_list.splice(
                              index,
                              1,
                            )
                      "
                      class="tbl_delete_btn"
                    >
                      delete
                    </button>
                  </td>
                </tr>
                <tr
                  v-show="managementMode || selectedIndex == -2"
                  class="newBtn new"
                >
                  <td>
                    <button
                      type="button"
                      class="tbl_search2"
                      @click="
                        (searchType == null || companyTypeId == null) &&
                        selectedIndex == -2
                          ? openOneButtonModal(
                              '거래처 선택',
                              '거래처를 선택 해주세요',
                            )
                          : (showResourceSearch = true)
                      "
                    ></button>
                  </td>
                  <td colspan="8"></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="btn_wrap">
            <div>
              <p>견적금액: {{ '₩ ' + $makeComma(accountQuote) }}</p>
            </div>
            <button type="button" class="btn_sub2" @click="submitForm()">
              {{ selectedIndex == -2 ? '등록' : '수정' }}
            </button>
            <div class="input_text">
              <p>비고:</p>
              <p v-show="!managementMode">
                {{ managementData.detail }}
              </p>
              <textarea
                type="text"
                v-show="managementMode || selectedIndex == -2"
                :value="managementData == null ? '' : managementData.detail"
                @input="
                  $event => {
                    managementData.detail = $event.target.value;
                  }
                "
              />
            </div>
          </div>
        </div>
      </div>
    </transition>
    <resource-search
      v-if="showResourceSearch"
      :filter_type="
        searchType == null
          ? managementData.purchase_type_id == null
            ? 2
            : 1
          : searchType == 1
          ? 2
          : 1
      "
      @onclose="showResourceSearch = false"
      @onselect="pushResource($event)"
    ></resource-search>
    <company-search
      v-if="showCompanySearch"
      :search_type="searchType"
      @onclose="showCompanySearch = false"
      @onselect="
        $event => {
          pushNewCompany($event);
          showCompanySearch = false;
        }
      "
    ></company-search>
    <two-button-modal
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      :index="my_modal_index"
      @onclose="CloseModal"
      @OnYesClick="deleteCompany($event)"
    ></two-button-modal>
  </div>
</template>

<script>
import XLSX from 'xlsx';
import { mapGetters } from 'vuex';
import SpinnerMixin from '@/mixins/spinner';
import MyLocalSelectric from '@/layouts/components/MyLocalSelectric';
import ResourceSearch from '@/layouts/components/search-popup/ResourceSearch';
import CompanySearch from '@/layouts/components/search-popup/CompanySearch';
import FavoriteMixin from '@/mixins/favorite';
import FETCH_MIXIN from '@/mixins/fetch';
import PriceMixin from '@/mixins/material_price';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
import { yyyymmdd, yyyy년MM월dd일HH시mm분ss초 } from '@/utils/func';
export default {
  mixins: [SpinnerMixin, FavoriteMixin, FETCH_MIXIN, PriceMixin],
  components: {
    MyLocalSelectric,
    ResourceSearch,
    CompanySearch,
    TwoButtonModal,
  },
  data() {
    return {
      showCompanySearch: false,
      showResourceSearch: false,
      companyTypeId: null,

      my_modal_title: '',
      my_modal_content: '',
      my_modal_index: -1,
      my_isModalOpen: false,

      companyOption: [
        {
          label: '선택',
          value: null,
        },
        {
          label: '매출처',
          value: 1,
        },
        {
          label: '구매처',
          value: 2,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromExchangeManagementPage',
      managementData: 'getManagementDataFromQuotation',
      quotation: 'getQuotation',
      selectedIndex: 'getSelectedIndexFromQuotation',
      start_date: 'getStartDateFromQuotation',
      end_date: 'getEndDateFromQuotation',
      company_types: 'getCompanyType',
      searchType: 'getSearchTypeFromQuotation',
      company_options_all: 'getCompanyTypeForSelectricAll',
      companys: 'getVisibleCompany',
      materials: 'getMaterial',
      units: 'getUnitCodes',
      products: 'getProduct',
    }),
    filteredQuotation() {
      if (this.quotation.length == 0 && this.quotation == undefined) {
        return [];
      } else {
        const quotations = this.lodash
          .clonedeep(this.quotation)
          .filter(x =>
            this.searchType == 1
              ? x.sales_type_id
              : this.searchType == 2
              ? x.purchase_type_id
              : x,
          )
          .filter(x =>
            this.companyTypeId != null ? x.company_id == this.companyTypeId : x,
          )
          .sort(
            (a, b) => new Date(b.quotation_date) - new Date(a.quotation_date),
          );

        return quotations;
      }
    },
    accountQuote() {
      let account =
        this.managementData == null
          ? ''
          : this.managementData.purchase_type_id != null
          ? this.managementData.quotation_material_list
              .map(x => x.total_cost)
              .reduce((a, b) => this.$decimalAdd(a, b), 0)
          : this.managementData.quotation_product_list
              .map(x => x.total_cost)
              .reduce((a, b) => this.$decimalAdd(a, b), 0);
      return account;
    },
  },
  methods: {
    ShowModal(index) {
      this.my_modal_index = index;
      this.my_modal_title = '경고';
      this.my_modal_content = '정말로 삭제하시겠습니까?';
      this.my_isModalOpen = true;
    },
    CloseModal() {
      this.my_modal_index = -1;
      this.my_modal_title = '';
      this.my_modal_content = '';
      this.my_isModalOpen = false;
    },

    async SelectRow(index) {
      this.$store.commit('setSelectedIndexToQuotation', index);
      if (index == -2) {
        await this.$store.commit('setManagementDataToQuotation', {
          company_id: null,
          create_time: '',
          detail: '',
          id: -1,
          purchase_type_id: null,
          quotation_date: yyyymmdd(new Date()),
          quotation_material_list: [],
          quotation_product_list: [],
          quotation_value: 0,
          sales_type_id: null,
        });
      } else if (index != -1) {
        await this.$store.commit(
          'setManagementDataToQuotation',
          this.filteredQuotation[index],
        );
      } else {
        this.$store.commit('setManagementDataToQuotation', {});
      }
    },

    changeSelectric(type) {
      if (this.selectedIndex == -2) {
        if (type == 1) {
          this.managementData.sales_type_id = 1;
          this.managementData.purchase_type_id = null;
          this.managementData.quotation_material_list = [];
        } else {
          this.managementData.purchase_type_id = 1;
          this.managementData.sales_type_id = null;
          this.managementData.quotation_product_list = [];
        }
        this.managementData.company_id = null;
      } else {
        this.SelectRow(-1);
      }
      this.companyTypeId = null;

      this.$store.commit('setSearchTypeToQuotation', type);
    },
    changeMaterialProduct(item) {
      let changeList = {};
      if (this.managementData.purchase_type_id != null) {
        changeList.total = this.calSupplyTax(
          this.getCompanyInfo(this.managementData.company_id).vat,
          this.getMaterialInfo(item.material_id).tax,
          this.$makeNumber(item.quantity),
          this.$makeNumber(item.cost),
        ).total;
        changeList.name = this.getMaterialInfo(item.material_id).name;
        changeList.unit_name = this.findInfoFromId(
          this.units,
          this.getMaterialInfo(item.material_id).using_unit_id,
        ).name;
        changeList.tax = this.getMaterialInfo(item.material_id).tax;
      } else {
        changeList.total = this.calSupplyTax(
          this.getCompanyInfo(this.managementData.company_id).vat,
          this.getProductInfo(item.product_id).tax,
          this.$makeNumber(item.quantity),
          this.$makeNumber(item.cost),
        ).total;
        changeList.name = this.getProductInfo(item.product_id).name;
        changeList.unit_name = this.findInfoFromId(
          this.units,
          this.getProductInfo(item.product_id).stock_unit_id,
        ).name;
        changeList.tax = this.getProductInfo(item.product_id).tax;
      }
      return changeList;
    },
    getFirstPurchaseMaterial(index) {
      if (
        this.filteredQuotation[index].quotation_material_list[0] != undefined
      ) {
        const firstMaterial = this.getMaterialInfo(
          this.filteredQuotation[index].quotation_material_list[0].material_id,
        ).name;
        return this.filteredQuotation[index].quotation_material_list.length > 1
          ? firstMaterial +
              ' 외 ' +
              Number(
                this.filteredQuotation[index].quotation_material_list.length -
                  1,
              ) +
              '개 원자재'
          : firstMaterial;
      } else {
        return '';
      }
    },
    getFirstSalesProduct(index) {
      if (
        this.filteredQuotation[index].quotation_product_list[0] != undefined
      ) {
        const firstProduct = this.getProductInfo(
          this.filteredQuotation[index].quotation_product_list[0].product_id,
        ).name;
        return this.filteredQuotation[index].quotation_product_list.length > 1
          ? firstProduct +
              ' 외 ' +
              Number(
                this.filteredQuotation[index].quotation_product_list.length - 1,
              ) +
              '개 제품'
          : firstProduct;
      } else {
        return '';
      }
    },
    pushResource(arg) {
      this.managementData.purchase_type_id == null
        ? this.managementData.quotation_product_list.push({
            box_unit_id: null,
            cost: 0,
            description: '',
            id: null,
            product_id: arg.id,
            quantity: 0,
            quotation_id: null,
            total_cost: 0,
          })
        : this.managementData.quotation_material_list.push({
            box_unit_id: null,
            cost: 0,
            description: '',
            id: null,
            material_id: arg.id,
            quantity: 0,
            quotation_id: null,
            total_cost: 0,
          });

      this.showResourceSearch = false;
    },
    getQuotationsCost(index) {
      let quote_cost = 0;
      if (this.filteredQuotation[index].purchase_type_id != null) {
        this.filteredQuotation[index].quotation_material_list.forEach(el => {
          quote_cost += el.total_cost;
        });
      } else {
        this.filteredQuotation[index].quotation_product_list.forEach(el => {
          quote_cost += el.total_cost;
        });
      }
      return this.$makeComma(quote_cost);
    },
    pushNewCompany(arg) {
      if (this.selectedIndex != -2) {
        this.SelectRow(-1);
      } else {
        this.managementData.company_id = arg.id;
      }
      this.companyTypeId = arg.id;
    },
    getMaterialInfo(id) {
      let hit = this.materials.find(x => x.id == id);
      return hit != undefined ? hit : '';
    },
    getProductInfo(id) {
      let hit = this.products.find(x => x.id == id);
      return hit != undefined ? hit : '';
    },
    getCompanyInfo(id) {
      let hit = this.companys.find(x => x.id == id);
      return hit != undefined ? hit : '';
    },
    reCalCost(row, newSalesValue) {
      const total_value = this.$makeNumber(newSalesValue);
      console.log('total_value', total_value);
      const company_vat = this.companys.find(
        x => x.id == this.managementData.company_id,
      ).vat;
      const product_tax = row.tax_id;
      let rowQuantity = this.$decimalMul(
        this.$makeNumber(row.quantity),
        row.box_unit_id != null ? this.getBoxQuantity(row.box_unit_id) : 1,
      );
      if (rowQuantity === 0) {
        row.quantity = 1;
        rowQuantity = 1;
      }

      if (company_vat != 1 || product_tax == 2) {
        row.cost = 0;
        row.cost = this.$makeNumber(
          this.$decimalDiv(total_value, this.$makeNumber(rowQuantity)).toFixed(
            0,
          ),
        );
      } else {
        const new_total_value = this.$makeNumber(
          this.$decimalDiv(total_value, 1.1).toFixed(0),
        );
        row.cost = 0;
        row.cost = this.$makeNumber(
          this.$decimalDiv(
            new_total_value,
            this.$makeNumber(rowQuantity),
          ).toFixed(0),
        );
      }
      row.total_cost = this.calSupplyTax(
        this.getCompanyInfo(this.managementData.company_id).vat,
        this.getMaterialInfo(row.material_id).tax,
        this.$makeNumber(row.quantity),
        row.cost,
      ).total;
    },
    async setStartDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.end_date) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최후날짜(${this.end_date}) 이후의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.start_date;
        } else {
          this.$store.commit('setStartDateToQuotation', e.target.value);
          await this.FETCH_WITH_PAYLOAD(
            'FETCH_QUOTATION',
            {
              start: this.start_date,
              end: this.end_date,
            },
            '견적서',
          ); //날짜
          // this.FETCH_QUOTATION();
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setStartDateToQuotation', yyyymmdd(new Date()));
      }
    },
    async setEndDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.start_date) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최초날짜(${this.start_date}) 이전의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.end_date;
        } else {
          this.$store.commit('setEndDateToQuotation', e.target.value);
          await this.FETCH_WITH_PAYLOAD(
            'FETCH_QUOTATION',
            {
              start: this.start_date,
              end: this.end_date,
            },
            '견적서',
          ); //날짜
          // this.FETCH_QUOTATION();
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setEndDateToQuotation', yyyymmdd(new Date()));
      }
    },
    async deleteCompany(arg) {
      this.showSpinner();
      //박스 삭제
      this.$store
        .dispatch('DELETE_QUOTATION', this.filteredQuotation[arg].id)
        .then(() => {
          this.SelectRow(-1);
          this.FETCH_WITH_PAYLOAD(
            'FETCH_QUOTATION',
            {
              start: this.start_date,
              end: this.end_date,
            },
            '견적서',
          ); //날짜
        })
        .catch(() => {
          this.openOneButtonModal('삭제 오류', '삭제 중 오류 발생.');
        })
        .finally(() => {
          this.hideSpinner();
          this.CloseModal();
        });

      this.hideSpinner();
    },
    async submitForm() {
      this.showSpinner();
      let payload = this.lodash.clonedeep(this.managementData);

      if (payload.purchase_type_id != null) {
        payload.quotation_material_list.forEach(el => {
          el.cost = this.$makeNumber(el.cost);
          el.quantity = this.$makeNumber(el.quantity);
          el.total_cost = this.calSupplyTax(
            this.getCompanyInfo(this.managementData.company_id).vat,
            this.getMaterialInfo(el.material_id).tax,
            this.$makeNumber(el.quantity),
            el.cost,
          ).total;
        });
      } else {
        payload.quotation_product_list.forEach(el => {
          el.cost = this.$makeNumber(el.cost);
          el.quantity = this.$makeNumber(el.quantity);
          el.total_cost = this.calSupplyTax(
            this.getCompanyInfo(this.managementData.company_id).vat,
            this.getProductInfo(el.product_id).tax,
            this.$makeNumber(el.quantity),
            el.cost,
          ).total;
        });
      }
      payload.quotation_value = this.$makeNumber(this.accountQuote);
      if (this.selectedIndex == -2) {
        await this.$store
          .dispatch('INSERT_QUOTATION', payload)
          .then(async () => {
            this.openOneButtonModal('등록 성공', '성공적으로 등록하였습니다.');
            await this.$store.commit('setManagementDataToQuotation', payload);
            await this.FETCH_WITH_PAYLOAD(
              'FETCH_QUOTATION',
              {
                start: this.start_date,
                end: this.end_date,
              },
              '견적서',
            ); //날짜
            this.companyTypeId = null;
            this.$store.commit('setSearchTypeToQuotation', null);
            this.SelectRow(-1);
          })
          .catch(error => {
            console.log(error);
            this.openOneButtonModal('등록 실패', '등록에 실패하였습니다.');
          })
          .finally(() => {
            this.hideSpinner();
          });
      } else {
        await this.$store
          .dispatch('UPDATE_QUOTATION', payload)
          .then(async () => {
            this.openOneButtonModal('수정 성공', '성공적으로 수정하였습니다.');
            await this.$store.commit('setManagementDataToQuotation', payload);
            await this.FETCH_WITH_PAYLOAD(
              'FETCH_QUOTATION',
              {
                start: this.start_date,
                end: this.end_date,
              },
              '견적서',
            ); //날짜
            this.SelectRow(-1);
          })
          .catch(error => {
            console.log(error);
            this.openOneButtonModal('수정 중 오류', '제품 수정 중 오류 발생');
          })
          .finally(() => {
            this.hideSpinner();
          });
      }
    },
    exportExcel() {
      let quoteList = [];
      let date = this.start_date + '~' + this.end_date;
      // let quote = this.filteredQuotation.quotation_material_list;
      const merge = [
        { s: { r: 0, c: 1 }, e: { r: 0, c: 4 } },
        { s: { r: 1, c: 1 }, e: { r: 1, c: 4 } },
      ];
      let standardInfo = [
        ['기준시간', yyyy년MM월dd일HH시mm분ss초(new Date())],
        ['조회기간', date],
        [],
        [],
        [],
      ];
      quoteList = quoteList.concat(standardInfo);

      quoteList.push([
        'No.',
        '항목',
        '비고',
        '과세여부',
        '단위',
        '수량',
        '단가',
        '금액',
      ]);
      if (this.managementData.purchase_type_id != null) {
        this.managementData.quotation_material_list.forEach((el, index) => {
          quoteList.push([
            index + 1,
            this.getMaterialInfo(el.material_id).name,
            el.description,
            this.getMaterialInfo(el.material_id).tax ? '과세' : '면세',
            this.findInfoFromId(
              this.units,
              this.getMaterialInfo(el.material_id).using_unit_id,
            ).name,
            this.$makeComma(el.cost),
            this.$makeComma(el.quantity),
            this.$makeComma(el.total_cost),
          ]);
        });
      } else {
        this.managementData.quotation_product_list.forEach((el, index) => {
          quoteList.push([
            index + 1,
            this.getProductInfo(el.product_id).name,
            el.description,
            this.getProductInfo(el.product_id).tax ? '과세' : '면세',
            this.findInfoFromId(
              this.units,
              this.getProductInfo(el.product_id).stock_unit_id,
            ).name,
            this.$makeComma(el.cost),
            this.$makeComma(el.quantity),
            this.$makeComma(el.total_cost),
          ]);
        });
      }

      let wb = XLSX.utils.book_new();
      let complete = XLSX.utils.json_to_sheet(quoteList, {
        skipHeader: true,
      });

      complete['!merges'] = merge;

      XLSX.utils.book_append_sheet(wb, complete, '견적서조회');

      let title = '견적서조회(' + date + ').xls';
      XLSX.writeFile(wb, title);
    },
  },
  async created() {
    if (this.start_date == null) {
      const date = new Date();
      this.$store.commit('setEndDateToQuotation', yyyymmdd(date));
      const date2 = new Date(date.setDate(date.getDate() - 7));
      this.$store.commit('setStartDateToQuotation', yyyymmdd(date2));
    }
    if (this.quotation.length < 1) {
      await this.FETCH_WITH_PAYLOAD(
        'FETCH_QUOTATION',
        {
          start: this.start_date,
          end: this.end_date,
        },
        '견적서',
      ); //날짜
    }
    if (this.company_types.length == 0) {
      await this.FETCH('FETCH_COMPANY_TYPE', '거래처 구분');
    }
    if (this.materials.length == 0) {
      await this.FETCH('FETCH_MATERIAL', '원자재');
    }
    if (this.products.length == 0) {
      await this.FETCH('FETCH_PRODFUCT', '제품');
    }
    if (this.units.length == 0) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.companys.length == 0) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
  },
};
</script>

<style lang="scss" scoped>
/* 애니메이션 진입 및 진출은 다른 지속 시간 및  */
/* 타이밍 기능을 사용할 수 있습니다. */
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.55s ease-in-out;
}
.slide-fade-enter {
  transform: translateX(100%);
}
.slide-fade-leave-to {
  transform: translateX(100%);
}
</style>
