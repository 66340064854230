<template>
  <div
    id="contents"
    :class="{
      collection_management: tabIndex == 0,
      expense_management: tabIndex == 1,
      quote_management: tabIndex == 2,
      transaction_report: tabIndex == 3,
    }"
  >
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <AsideSelectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToExchangeManagementPage'"
          :options="asideLists"
        />
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <a
            @click.prevent="ToggleManagementMode()"
            :class="managementMode ? 'btn_admin on' : 'btn_admin'"
            v-if="isPermissionOn && tabIndex !== 3"
            href="#"
            >관리</a
          >
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li
            v-for="(asideList, index) in asideLists"
            :key="index"
            :class="{ active: tabIndex == index }"
          >
            <a
              href="#"
              @click.prevent="SetOpenTabIndex(index)"
              v-if="isPermissionOn"
              >{{ asideList.title }}</a
            >
          </li>
        </ul>
      </div>
      <CollectionManagementForm v-if="tabIndex == 0" />
      <ExpenseManagementForm v-if="tabIndex == 1" />
      <QuoteManagementForm v-if="tabIndex == 2" />
      <TransactionReportForm v-if="tabIndex == 3" />
    </div>
  </div>
</template>

<script>
import CollectionManagementForm from '@/views/forms/Business/CollectionManagementForm';
import ExpenseManagementForm from '@/views/forms/Business/ExpenseManagementForm';
import QuoteManagementForm from '@/views/forms/Business/QuoteManagementForm';
import TransactionReportForm from '@/views/forms/Business/TransactionReportForm';
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import { mapGetters, mapMutations } from 'vuex';

export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  components: {
    AsideSelectric,
    CollectionManagementForm,
    ExpenseManagementForm,
    QuoteManagementForm,
    TransactionReportForm,
  },
  data() {
    return {
      asideLists: [
        { title: '수금관리', name: 'collection_management' },
        { title: '지출관리', name: 'expense_management' },
        { title: '견적서 관리', name: 'quote_management' },
        { title: '거래보고서', name: 'transaction_report' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromExchangeManagementPage',
      tabIndex: 'getOpenTabIndexFromExchangeManagementPage',
    }),
  },
  methods: {
    ...mapMutations({
      ToggleManagementMode: 'toggleManagementModeToExchangeManagementPage',
      SetOpenTabIndex: 'setOpenTabIndexToExchangeManagementPage',
    }),
  },
  created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitExchangePageStore');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style scoped></style>
