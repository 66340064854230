<template>
  <div class="article">
    <div class="transaction_table tbl_wrap">
      <div class="tab_wrap">
        <div class="tab_list">
          <ul>
            <li
              :class="{ active: tabIndex == 0 }"
              @click.prevent="selectTabIndex(0)"
            >
              수금현황
            </li>
            <li
              :class="{ active: tabIndex == 1 }"
              @click.prevent="selectTabIndex(1)"
            >
              지출현황
            </li>
          </ul>
        </div>
        <div class="tab_cont">
          <div class="tab01" v-if="tabIndex === 0">
            <form>
              <div class="tbl_option">
                <div class="search_opt">
                  <div class="input_text">
                    <label>조회 기간</label>
                    <input
                      type="date"
                      placeholder="YYYY-MM-DD"
                      :value="collection_start_date"
                      @change="setStartDate($event)"
                    />
                  </div>
                  <p>~</p>
                  <div class="input_text">
                    <input
                      type="date"
                      placeholder="YYYY-MM-DD"
                      @change="setEndDate($event)"
                      :value="collection_end_date"
                    />
                  </div>
                  <button
                    type="button"
                    class="excel_btn"
                    @click.prevent="exportExcel"
                  >
                    엑셀로 출력
                  </button>
                </div>
                <div class="input_radio">
                  <div>
                    <label
                      for="radio1"
                      class="label"
                      :class="{ active: this.radioList.check_all }"
                    ></label>
                    <input
                      type="radio"
                      id="radio1"
                      name="search_opt"
                      @click="setCheckedRadio('check_all')"
                    />
                    <label for="radio1" class="label_txt"
                      >전체 매출처 조회</label
                    >
                  </div>
                  <div>
                    <label
                      for="radio2"
                      class="label"
                      :class="{ active: this.radioList.check_include }"
                    ></label>
                    <input
                      type="radio"
                      id="radio2"
                      name="search_opt"
                      @click="setCheckedRadio('check_include')"
                    />
                    <label for="radio2" class="label_txt"
                      >매출처 포함 조회</label
                    >
                  </div>
                  <div>
                    <label
                      for="radio3"
                      class="label"
                      :class="{ active: this.radioList.check_exclude }"
                    ></label>
                    <input
                      type="radio"
                      id="radio3"
                      name="search_opt"
                      @click="setCheckedRadio('check_exclude')"
                    />
                    <label for="radio3" class="label_txt"
                      >매출처 제외 조회</label
                    >
                  </div>
                </div>
                <div class="ledger_opt" v-show="!this.radioList.check_all">
                  <button
                    type="button"
                    class="btn_ledger"
                    @click="showCompanySearch = true"
                  >
                    {{ this.radioList.checkIn }} 거래처 선택
                  </button>
                  <transition-group
                    name="selected"
                    mode="in-out"
                    tag="ul"
                    type="transition"
                    class="selected_items_wrap"
                    v-if="filterArray.length > 0"
                  >
                    <li
                      class="selected_item"
                      v-for="(item, index) in filterArray"
                      :key="item.id"
                    >
                      <span>{{ findInfoFromId(companys, item.id).name }}</span>
                      <button
                        type="button"
                        class="delete_item"
                        @click="filterArray.splice(index, 1)"
                      ></button>
                    </li>
                  </transition-group>
                </div>
                <company-search
                  v-if="showCompanySearch"
                  :search_type="1"
                  :ignore_list="filterArray"
                  @onclose="showCompanySearch = false"
                  @onselect="selectedRadio($event)"
                ></company-search>
              </div>
            </form>
            <h6 class="views">조회수 : {{ filtered_collections.length }}건</h6>
            <div class="mes_tbl_wrap mat_print_table">
              <table class="mes_tbl">
                <thead>
                  <tr>
                    <th>수금일</th>
                    <th>판매처명</th>
                    <th>판매항목</th>
                    <th>수량(단위)</th>
                    <th>수금액</th>
                    <th>수금구분</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in filtered_collections" :key="item.id">
                    <td>{{ item.input_date }}</td>
                    <td class="text_left">
                      {{ findInfoFromId(companys, item.company_id).name }}
                    </td>
                    <td>
                      {{
                        findInfoFromId(
                          products,
                          findInfoFromId(filteredSales, item.sales_product_id)
                            .product_id,
                        ).name
                      }}
                    </td>
                    <td>
                      {{
                        findInfoFromId(filteredSales, item.sales_product_id)
                          .quantity
                      }}
                      {{
                        findInfoFromId(filteredSales, item.sales_product_id)
                          .quantity != null
                          ? `(${
                              findInfoFromId(
                                units,
                                findInfoFromId(
                                  filteredSales,
                                  item.sales_product_id,
                                ).unit_id,
                              ).name
                            })`
                          : ''
                      }}
                    </td>
                    <td class="text_right">
                      &#8361; {{ $makeComma(item.total_value) }}
                    </td>
                    <td>
                      {{
                        findInfoFromId(collectionType, item.collection_type_id)
                          .name
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="tab02" v-else>
            <form>
              <div class="tbl_option">
                <div class="search_opt">
                  <div class="input_text">
                    <label>조회 기간</label>
                    <input
                      type="date"
                      placeholder="YYYY-MM-DD"
                      :value="payment_start_date"
                      @change="setStartDate2($event)"
                    />
                  </div>
                  <p>~</p>
                  <div class="input_text">
                    <input
                      type="date"
                      placeholder="YYYY-MM-DD"
                      :value="payment_end_date"
                      @change="setEndDate2($event)"
                    />
                  </div>
                  <button
                    type="button"
                    class="excel_btn"
                    @click.prevent="exportExcel2"
                  >
                    엑셀로 출력
                  </button>
                </div>
                <div class="input_radio">
                  <div>
                    <label
                      for="radio1"
                      class="label"
                      :class="{ active: this.radioList.check_all }"
                    ></label>
                    <input
                      type="radio"
                      id="radio1"
                      name="search_opt"
                      @click="setCheckedRadio('check_all')"
                    />
                    <label for="radio1" class="label_txt"
                      >전체 지출처 조회</label
                    >
                  </div>
                  <div>
                    <label
                      for="radio2"
                      class="label"
                      :class="{ active: this.radioList.check_include }"
                    ></label>
                    <input
                      type="radio"
                      id="radio2"
                      name="search_opt"
                      @click="setCheckedRadio('check_include')"
                    />
                    <label for="radio2" class="label_txt"
                      >지출처 포함 조회</label
                    >
                  </div>
                  <div>
                    <label
                      for="radio3"
                      class="label"
                      :class="{ active: this.radioList.check_exclude }"
                    ></label>
                    <input
                      type="radio"
                      id="radio3"
                      name="search_opt"
                      @click="setCheckedRadio('check_exclude')"
                    />
                    <label for="radio3" class="label_txt"
                      >지출처 제외 조회</label
                    >
                  </div>
                </div>
                <div class="ledger_opt" v-show="!this.radioList.check_all">
                  <button
                    type="button"
                    class="btn_ledger"
                    @click="showCompanySearch = true"
                  >
                    {{ this.radioList.checkIn }} 거래처 선택
                  </button>
                  <transition-group
                    name="selected"
                    mode="in-out"
                    tag="ul"
                    type="transition"
                    class="selected_items_wrap"
                    v-if="filterArray.length > 0"
                  >
                    <li
                      class="selected_item"
                      v-for="(item, index) in filterArray"
                      :key="item.id"
                    >
                      <span>{{ findInfoFromId(companys, item.id).name }}</span>
                      <button
                        type="button"
                        class="delete_item"
                        @click="filterArray.splice(index, 1)"
                      ></button>
                    </li>
                  </transition-group>
                </div>
                <company-search
                  v-if="showCompanySearch"
                  :search_type="2"
                  :ignore_list="filterArray"
                  @onclose="showCompanySearch = false"
                  @onselect="selectedRadio($event)"
                ></company-search>
              </div>
            </form>
            <h6 class="views">조회수 : {{ filtered_payments.length }}건</h6>
            <div class="mes_tbl_wrap mat_print_table">
              <table class="mes_tbl">
                <thead>
                  <tr>
                    <th>지출일</th>
                    <th>구매처</th>
                    <th>구매항목</th>
                    <th>수량(단위)</th>
                    <th>지출액</th>
                    <th>지출구분</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in filtered_payments" :key="item.id">
                    <td>{{ item.input_date }}</td>
                    <td class="text_left">
                      {{ findInfoFromId(companys, item.company_id).name }}
                    </td>
                    <td>
                      {{
                        findInfoFromId(
                          materials,
                          findInfoFromId(
                            filteredPurchase,
                            item.purchase_material_id,
                          ).material_id,
                        ).name
                      }}
                    </td>
                    <td>
                      {{
                        findInfoFromId(
                          filteredPurchase,
                          item.purchase_material_id,
                        ).quantity
                      }}
                      {{
                        findInfoFromId(
                          filteredPurchase,
                          item.purchase_material_id,
                        ).quantity != null
                          ? `(${
                              findInfoFromId(
                                units,
                                findInfoFromId(
                                  filteredPurchase,
                                  item.purchase_material_id,
                                ).unit_id,
                              ).name
                            })`
                          : ''
                      }}
                    </td>
                    <td class="text_right">
                      &#8361; {{ $makeComma(item.total_value) }}
                    </td>
                    <td>
                      {{
                        findInfoFromId(payment_types, item.payment_type_id).name
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SpinnerMixin from '@/mixins/spinner';
import FavoriteMixin from '@/mixins/favorite';
import FETCH_MIXIN from '@/mixins/fetch';
import CompanySearch from '@/layouts/components/search-popup/CompanySearch';
import { yyyymmdd, yyyy년MM월dd일HH시mm분ss초 } from '@/utils/func';
import XLSX from 'xlsx';
export default {
  mixins: [SpinnerMixin, FavoriteMixin, FETCH_MIXIN],
  components: {
    CompanySearch,
  },
  data() {
    return {
      tabIndex: 0,
      showCompanySearch: false,
    };
  },
  computed: {
    ...mapGetters({
      //collection
      newSalesSrc: 'getNewSalesFromOutput',
      collections: 'getExchangeCollection',
      sales_type: 'getSalesType',
      collectionType: 'getCollectionType',
      collection_start_date: 'getStartDateFromExchangeCollection',
      collection_end_date: 'getEndDateFromExchangeCollection',
      searchType: 'getSearchTypeFromExchangeCollection',
      //common
      radioList: 'getCheckedRadioFromTransactionReport',
      filterArray: 'getFilterArrayFromTransactionReport',
      products: 'getProduct',
      units: 'getUnitCodes',
      companys: 'getCompany',
      //payment
      payments: 'getPayments',
      payment_types: 'getPaymentTypes',
      search_type: 'getSearchTypeFromExchangePayment',
      payment_start_date: 'getStartDateFromExchangePayment',
      payment_end_date: 'getEndDateFromExchangePayment',
      purchase_account: 'getPurchaseAccountFromInput',
      materials: 'getMaterial',
    }),
    filtered_collections() {
      if (this.collections != undefined) {
        let clone = this.lodash
          .clonedeep(this.collections)
          .filter(
            x =>
              (x.collection_type_id == this.searchType ||
                this.searchType == null) &&
              x.sales_account_id == null,
          );
        if (this.filterArray.length > 0) {
          const check = this.filterArray.map(x => x.id);
          if (this.radioList.check_include) {
            clone = this.collections.filter(x => check.includes(x.company_id));
          } else if (this.radioList.check_exclude) {
            clone = this.collections.filter(x => !check.includes(x.company_id));
          } else if (this.radioList.check_all) {
            clone;
          } else {
            return [];
          }
        }
        return clone;
      } else {
        return [];
      }
    },
    filteredSales() {
      if (this.newSalesSrc != undefined && this.newSalesSrc.length > 0) {
        let clone = this.lodash
          .clonedeep(this.newSalesSrc)
          .filter(
            x =>
              !this.findInfoFromId(
                this.sales_type,
                x.sales.sales_type_id,
              ).detail.includes('return'),
          )
          .map(x =>
            x.sales_product_list.map(y => ({
              ...y,
              create_time: x.create_time,
            })),
          )
          .reduce((a, b) => a.concat(b), []);
        return clone;
      } else {
        return [];
      }
    },

    filtered_payments() {
      if (this.payments != undefined) {
        let clone = this.lodash
          .clonedeep(this.payments)
          .filter(
            x =>
              x.payment_type_id == this.search_type || this.search_type == null,
          );
        if (this.filterArray.length > 0) {
          const check = this.filterArray.map(x => x.id);
          if (this.radioList.check_include) {
            clone = this.payments.filter(x => check.includes(x.company_id));
          } else if (this.radioList.check_exclude) {
            clone = this.payments.filter(x => !check.includes(x.company_id));
          } else if (this.radioList.check_all) {
            clone;
          } else {
            return [];
          }
        }
        return clone;
      } else {
        return [];
      }
    },

    filteredPurchase() {
      if (
        this.purchase_account != undefined &&
        this.purchase_account.length > 0
      ) {
        const clone = this.lodash
          .clonedeep(this.purchase_account)
          .map(x =>
            x.purchase_material_list.map(y => ({
              ...y,
              create_time: x.create_time,
            })),
          )
          .reduce((a, b) => a.concat(b), []);
        return clone;
      } else {
        return [];
      }
    },
  },
  methods: {
    setCheckedRadio(checked) {
      this.$store.commit('setCheckedRadioToTransactionReport', checked);
      this.$store.commit('setFilterArrayToTransactionReport', []);
    },
    selectedRadio(arg) {
      this.filterArray.push(arg);
      this.showCompanySearch = false;
    },
    selectTabIndex(index) {
      this.tabIndex = index;
      this.setCheckedRadio(-1);
      // this.selectedRadio(-1);
    },
    async setStartDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.collection_end_date) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최후날짜(${this.collection_end_date}) 이후의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.collection_start_date;
        } else {
          this.$store.commit(
            'setStartDateToExchangeCollection',
            e.target.value,
          );
          await this.FETCH_EXCHANGE_COLLECTION();
          await this.FETCH_WITH_PAYLOAD(
            'FETCH_SALES_ACCOUNT_DAY_ALL_TO_OUTPUT',
            {
              start: `1950-01-01`,
              end: this.collection_end_date,
            },
            '매출 내역',
          );
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit(
          'setStartDateToExchangeCollection',
          yyyymmdd(new Date()),
        );
      }
    },
    async setEndDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.collection_start_date) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최초날짜(${this.collection_start_date}) 이전의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.collection_end_date;
        } else {
          this.$store.commit('setEndDateToExchangeCollection', e.target.value);
          await this.FETCH_EXCHANGE_COLLECTION();
          await this.FETCH_WITH_PAYLOAD(
            'FETCH_SALES_ACCOUNT_DAY_ALL_TO_OUTPUT',
            {
              start: `1950-01-01`,
              end: this.collection_end_date,
            },
            '매출 내역',
          );
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
      }
    },
    async setStartDate2(e) {
      if (e.target.value != '') {
        if (e.target.value > this.payment_end_date) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최후날짜(${this.payment_end_date}) 이후의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.payment_start_date;
        } else {
          this.$store.commit('setStartDateToExchangePayment', e.target.value);
          await this.FETCH_WITH_PAYLOAD(
            'FETCH_PAYMENT',
            {
              start: this.payment_start_date,
              end: this.payment_end_date,
            },
            '지급 리스트',
          );
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit(
          'setStartDateToExchangePayment',
          yyyymmdd(new Date()),
        );
      }
    },
    async setEndDate2(e) {
      if (e.target.value != '') {
        if (e.target.value < this.payment_start_date) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최초날짜(${this.payment_start_date}) 이전의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.payment_end_date;
        } else {
          this.$store.commit('setEndDateToExchangePayment', e.target.value);
          await this.FETCH_WITH_PAYLOAD(
            'FETCH_PAYMENT',
            {
              start: this.payment_start_date,
              end: this.payment_end_date,
            },
            '지급 리스트',
          ); //날짜
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setEndDateToExchangePayment', yyyymmdd(new Date()));
      }
    },
    async FETCH_EXCHANGE_COLLECTION() {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_EXCHANGE_COLLECTION', {
          start: this.collection_start_date,
          end: this.collection_end_date,
        })
        .then(() => {})
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '로드 중 오류',
            '수금 리스트를 불러오는 중 오류발생',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    exportExcel() {
      let transReport = [];
      let date = this.collection_start_date + '~' + this.collection_end_date;

      const merge = [
        { s: { r: 0, c: 1 }, e: { r: 0, c: 4 } },
        { s: { r: 1, c: 1 }, e: { r: 1, c: 4 } },
      ];
      let standardInfo = [
        ['기준시간', yyyy년MM월dd일HH시mm분ss초(new Date())],
        ['조회기간', date],
        [],
        [],
        [],
      ];
      transReport = transReport.concat(standardInfo);

      transReport.push([
        '수금일',
        '판매처명',
        '판매항목',
        '수량(단위)',
        '수금액',
        '수금구분',
      ]);

      this.filtered_collections.forEach(el => {
        transReport.push([
          el.input_date,
          this.findInfoFromId(this.companys, el.company_id).name,
          this.findInfoFromId(
            this.products,
            this.findInfoFromId(this.filteredSales, el.sales_product_id)
              .product_id,
          ).name,
          this.findInfoFromId(this.filteredSales, el.sales_product_id)
            .quantity != null
            ? this.findInfoFromId(this.filteredSales, el.sales_product_id)
                .quantity +
              '(' +
              this.findInfoFromId(
                this.units,
                this.findInfoFromId(this.filteredSales, el.sales_product_id)
                  .unit_id,
              ).name +
              ')'
            : '',

          '₩' + this.$makeComma(el.total_value),
          this.findInfoFromId(this.collectionType, el.collection_type_id).name,
        ]);
      });

      let wb = XLSX.utils.book_new();
      let complete = XLSX.utils.json_to_sheet(transReport, {
        skipHeader: true,
      });

      complete['!merges'] = merge;

      XLSX.utils.book_append_sheet(wb, complete, '거래보고서 수금현황');

      let title = '거래보고서 수금현황(' + date + ').xls';
      XLSX.writeFile(wb, title);
    },
    exportExcel2() {
      let transReport = [];
      let date = this.collection_start_date + '~' + this.collection_end_date;

      const merge = [
        { s: { r: 0, c: 1 }, e: { r: 0, c: 4 } },
        { s: { r: 1, c: 1 }, e: { r: 1, c: 4 } },
      ];
      let standardInfo = [
        ['기준시간', yyyy년MM월dd일HH시mm분ss초(new Date())],
        ['조회기간', date],
        [],
        [],
        [],
      ];
      transReport = transReport.concat(standardInfo);

      transReport.push([
        '지출일',
        '구매처명',
        '구매항목',
        '수량(단위)',
        '지출액',
        '지출구분',
      ]);

      this.filtered_payments.forEach(el => {
        transReport.push([
          el.input_date,
          this.findInfoFromId(this.companys, el.company_id).name,
          this.findInfoFromId(
            this.materials,
            this.findInfoFromId(this.filteredPurchase, el.purchase_material_id)
              .product_id,
          ).name,
          this.findInfoFromId(this.filteredPurchase, el.purchase_material_id)
            .quantity != null
            ? this.findInfoFromId(
                this.filteredPurchase,
                el.purchase_material_id,
              ).quantity +
              '(' +
              this.findInfoFromId(
                this.units,
                this.findInfoFromId(
                  this.filteredPurchase,
                  el.purchase_material_id,
                ).unit_id,
              ).name +
              ')'
            : '',

          '₩' + this.$makeComma(el.total_value),
          this.findInfoFromId(this.payment_types, el.payment_type_id).name,
        ]);
      });

      let wb = XLSX.utils.book_new();
      let complete = XLSX.utils.json_to_sheet(transReport, {
        skipHeader: true,
      });

      complete['!merges'] = merge;

      XLSX.utils.book_append_sheet(wb, complete, '거래보고서 지출현황');

      let title = '거래보고서 지출현황(' + date + ').xls';
      XLSX.writeFile(wb, title);
    },
  },
  async created() {
    if (this.collection_start_date == null) {
      const today = new Date();
      this.$store.commit('setEndDateToExchangeCollection', yyyymmdd(today));
      const lastMonth = new Date();
      lastMonth.setMonth(lastMonth.getMonth() - 1);
      this.$store.commit(
        'setStartDateToExchangeCollection',
        yyyymmdd(lastMonth),
      );
    }
    if (this.payment_start_date == null) {
      const today = new Date();
      this.$store.commit('setEndDateToExchangePayment', yyyymmdd(today));
      const lastMonth = new Date();
      lastMonth.setMonth(lastMonth.getMonth() - 1);
      this.$store.commit('setStartDateToExchangePayment', yyyymmdd(lastMonth));
    }

    if (this.sales_type.length < 1) {
      await this.FETCH('FETCH_SALES_TYPE', '매출유형');
    }
    if (this.products.length < 1) {
      this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.materials.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '원자재');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처 리스트');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.collectionType.length < 1) {
      await this.FETCH('FETCH_COLLECTION_TYPE', '수금구분');
    }
    if (this.payment_types.length < 1) {
      await this.FETCH('FETCH_PAYMENTS_TYPE', '지출구분');
    }
    await this.FETCH_WITH_PAYLOAD(
      'FETCH_SALES_ACCOUNT_DAY_ALL_TO_OUTPUT',
      {
        start: `1950-01-01`,
        end: this.collection_end_date,
      },
      '매출 내역',
    );
    await this.FETCH_EXCHANGE_COLLECTION();

    await this.FETCH_WITH_PAYLOAD(
      'FETCH_PAYMENT',
      {
        start: this.payment_start_date,
        end: this.payment_end_date,
      },
      '지급 리스트',
    );
    await this.FETCH_WITH_PAYLOAD(
      'FETCH_PURCHASE_ACCOUNT_DAY_ALL',
      {
        start: '1950-01-01',
        end: this.payment_end_date,
      },
      '구매 내역',
    );
  },
};
</script>

<style lang="scss" scoped>
.selected_item {
  transition: all 0.5s;
}
.selected-enter,
.selected-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.selected-leave-active {
  position: absolute;
}
</style>
