<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <div>
          <span class="option_txt">조회 기간</span>
          <div>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="start_date"
                @change="setStartDate($event)"
              />
            </div>
            <p>~</p>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                @change="setEndDate($event)"
                :value="end_date"
              />
            </div>
          </div>
        </div>
        <div>
          <div class="input_search">
            <input
              type="text"
              placeholder="매출처 검색"
              :value="searchText"
              @input="typing($event)"
            />
            <button>
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
        <div>
          <span class="option_txt">수금 구분</span>
          <div class="input_text">
            <my-local-selectric
              :id="'collection_type_selectric'"
              :watch="searchType"
              :options="collection_options_all"
              @changeValue="changeSelectric($event)"
            >
            </my-local-selectric>
          </div>
        </div>
      </div>
      <h3>지급 현황</h3>
      <h6>조회수 : {{ filtered_collections.length }}건</h6>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl" :class="{ manage: managementMode }">
          <colgroup>
            <col v-for="(n, index) in managementMode ? 5 : 4" :key="index" />
          </colgroup>
          <thead>
            <tr>
              <th>수금일</th>
              <th>매출처명</th>
              <th>판매항목</th>
              <th>수량</th>
              <th>수금액</th>
              <th>수금구분</th>
              <th v-show="managementMode">삭제</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in filtered_collections"
              :key="item.id"
              @click="SelectRow(index)"
              :class="selectedIndex == index ? 'active' : ''"
            >
              <td>{{ item.input_date }}</td>
              <td class="text_left">
                {{ findInfoFromId(companys, item.company_id).name }}
              </td>
              <td>
                {{
                  findInfoFromId(
                    products,
                    findInfoFromId(filteredSales, item.sales_product_id)
                      .product_id,
                  ).name
                }}
              </td>
              <td>
                {{
                  findInfoFromId(filteredSales, item.sales_product_id).quantity
                }}
                {{
                  findInfoFromId(filteredSales, item.sales_product_id)
                    .quantity != null
                    ? `(${
                        findInfoFromId(
                          units,
                          findInfoFromId(filteredSales, item.sales_product_id)
                            .unit_id,
                        ).name
                      })`
                    : ''
                }}
              </td>
              <td class="text_right">
                &#8361; {{ $makeComma(item.total_value) }}
              </td>
              <td>
                {{
                  findInfoFromId(collectionType, item.collection_type_id).name
                }}
              </td>
              <td v-show="managementMode">
                <button @click="ShowModal(index)" class="tbl_delete_btn">
                  delete
                </button>
              </td>
            </tr>
            <tr
              @click="SelectRow(-1)"
              v-show="managementMode"
              :class="selectedIndex == -1 ? 'new' : 'new_disable'"
              class="newBtn"
            >
              <td colspan="7" @click="SelectRow(-1)">
                + 신규 등록
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="aside_management_mode">
      <div class="head">
        <h5>세부정보</h5>
      </div>
      <div class="form" @submit.prevent>
        <form>
          <div>
            <div class="input_text">
              <label>매출처</label>
              <div class="input_search">
                <input
                  class="position"
                  type="text"
                  placeholder="매출처 검색"
                  :readonly="managementData.company_id != null"
                  :value="
                    findInfoFromId(companys, managementData.company_id).name
                  "
                  :disabled="!managementMode"
                />
                <div v-show="managementMode" v-if="isPermissionOn">
                  <button>
                    <i
                      class="fa fa-search"
                      @click="showCompanySearch = true"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="input_text">
              <label>수금일</label>
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                v-model="managementData.input_date"
                :readonly="!managementMode"
              />
            </div>
          </div>
          <div>
            <div class="input_text">
              <label>수금액</label>
              <input
                type="text"
                placeholder=""
                :value="$makeComma(managementData.total_value)"
                inputmode="decimal"
                @input="$inputNumberInt($event, managementData, 'total_value')"
                :disabled="!managementMode"
              />
            </div>
          </div>
          <div>
            <div class="input_text">
              <label>판매항목</label>
              <div class="input_search">
                <input
                  class="position"
                  type="text"
                  :placeholder="selectedIndex == -1 ? '판매항목 검색' : ''"
                  readonly
                  :value="
                    managementData.sales_product_id != null
                      ? `${
                          findInfoFromId(
                            filteredSales,
                            managementData.sales_product_id,
                          ).create_time != undefined
                            ? findInfoFromId(
                                filteredSales,
                                managementData.sales_product_id,
                              ).create_time.substr(0, 10)
                            : ''
                        } / ${
                          findInfoFromId(
                            products,
                            findInfoFromId(
                              filteredSales,
                              managementData.sales_product_id,
                            ).product_id,
                          ).name
                        } / ${
                          findInfoFromId(
                            filteredSales,
                            managementData.sales_product_id,
                          ).quantity
                        } ${
                          findInfoFromId(
                            units,
                            findInfoFromId(
                              filteredSales,
                              managementData.sales_product_id,
                            ).unit_id,
                          ).name
                        } `
                      : ''
                  "
                  :disabled="!managementMode"
                />
                <div v-show="managementMode" v-if="isPermissionOn">
                  <button>
                    <i
                      class="fa fa-search"
                      @click="showSalesItemSearch = true"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="input_text">
              <label>수금구분</label>
              <my-local-selectric
                :id="'collection_selectric'"
                :watch="managementData.collection_type_id"
                :options="collection_options"
                @changeValue="setManagementCollectionType($event)"
                :class="managementMode ? '' : 'disabled'"
              >
              </my-local-selectric>
            </div>
            <div
              v-if="
                collectionType
                  .filter(x => x.date_yn)
                  .map(x => x.id)
                  .includes(managementData.collection_type_id)
              "
            >
              <div class="input_text">
                <label>상환기간</label>
                <input
                  type="date"
                  placeholder="YYYY-MM-DD"
                  v-model="managementData.collecting_date"
                />
              </div>
            </div>
          </div>
          <div class="btn_wrap" v-show="managementMode" v-if="isPermissionOn">
            <button class="btn_sub2" @click="submitForm()">
              {{ selectedIndex == -1 ? '등록' : '수정' }}
            </button>
          </div>
        </form>
      </div>
    </div>
    <two-button-modal
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      :index="my_modal_index"
      @onclose="CloseModal"
      @OnYesClick="deleteCompany($event)"
    ></two-button-modal>
    <company-search
      v-if="showCompanySearch"
      :search_type="1"
      @onclose="showCompanySearch = false"
      @onselect="pushManagementCompany($event)"
    ></company-search>
    <SalesItemSearch
      v-if="showSalesItemSearch"
      :search_type="1"
      @onclose="showSalesItemSearch = false"
      @onselect="pushProductsToList($event)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import FavoriteMixin from '@/mixins/favorite';
import FETCH_MIXIN from '@/mixins/fetch';
import PriceMixin from '@/mixins/price';
import MyLocalSelectric from '@/layouts/components/MyLocalSelectric';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
import CompanySearch from '@/layouts/components/search-popup/CompanySearch';
import SalesItemSearch from '@/layouts/components/search-popup/SalesItemSearch';
import { yyyymmdd } from '@/utils/func';
export default {
  mixins: [ModalMixin, SpinnerMixin, FavoriteMixin, FETCH_MIXIN, PriceMixin],
  components: {
    MyLocalSelectric,
    TwoButtonModal,
    CompanySearch,
    SalesItemSearch,
  },
  data() {
    return {
      showCompanySearch: false,
      showSalesItemSearch: false,
      searchText: '',
      //delete modal
      my_modal_title: '',
      my_modal_content: '',
      my_modal_index: -1,
      my_isModalOpen: false,
      // initial value
    };
  },
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromExchangeManagementPage',
      managementData: 'getManagementDataFromExchangeCollection',
      newSalesSrc: 'getNewSalesFromOutput',
      collections: 'getExchangeCollection',
      sales_type: 'getSalesType',
      collectionType: 'getCollectionType',
      collection_options: 'getCollectionTypeForSelectric',
      collection_options_all: 'getCollectionTypeForSelectricAll',
      selectedIndex: 'getSelectedIndexFromExchangeCollection',
      searchType: 'getSearchTypeFromExchangeCollection',
      start_date: 'getStartDateFromExchangeCollection',
      end_date: 'getEndDateFromExchangeCollection',
      products: 'getProduct',
      units: 'getUnitCodes',
      companys: 'getCompany',
    }),
    filtered_collections() {
      const Hangul = require('hangul-js');
      return this.collections
        .filter(
          x =>
            (x.collection_type_id == this.searchType ||
              this.searchType == null) &&
            x.sales_account_id == null,
        )
        .filter(
          x =>
            this.findInfoFromId(this.companys, x.company_id).name.includes(
              this.searchText,
            ) ||
            Hangul.d(
              this.findInfoFromId(this.companys, x.company_id).name,
              true,
            )
              .map(x => x[0])
              .join('')
              .includes(this.searchText),
        );
    },
    filteredSales() {
      if (this.newSalesSrc != undefined && this.newSalesSrc.length > 0) {
        let clone = this.lodash
          .clonedeep(this.newSalesSrc)
          .filter(
            x =>
              !this.findInfoFromId(
                this.sales_type,
                x.sales.sales_type_id,
              ).detail.includes('return'),
          )
          .map(x =>
            x.sales_product_list.map(y => ({
              ...y,
              create_time: x.create_time,
            })),
          )
          .reduce((a, b) => a.concat(b), []);
        return clone;
      } else {
        return [];
      }
    },
    checkValidType() {
      return this.managementData.collection_type_id == null ? false : true;
    },
    checkValidTotalValue() {
      if (
        this.managementData.total_value == undefined ||
        this.managementData.total_value == null ||
        String(this.$makeNumber(this.managementData.total_value)).trim() ==
          '0' ||
        String(this.managementData.total_value).trim() == ''
      ) {
        return false;
      } else return true;
    },
  },
  methods: {
    ShowModal(index) {
      this.my_modal_index = index;
      this.my_modal_title = '경고';
      this.my_modal_content = '정말로 삭제하시겠습니까?';
      this.my_isModalOpen = true;
    },
    CloseModal() {
      this.my_modal_index = -1;
      this.my_modal_title = '';
      this.my_modal_content = '';
      this.my_isModalOpen = false;
    },
    typing(e) {
      this.searchText = e.target.value.trim();
      this.SelectRow(-1);
    },
    pushManagementCompany(arg) {
      if (this.showCompanySearch) {
        this.showCompanySearch = false;
      }
      this.managementData.company_id = arg.id;
    },
    pushProductsToList(arg) {
      if (this.showSalesItemSearch) {
        this.showSalesItemSearch = false;
      }
      this.managementData.sales_product_id = arg.id;
    },
    SelectRow(index) {
      this.$store.commit('setSelectedIndexToExchangeCollection', index);
      if (index == -1) {
        this.$store.commit('setManagementDataToExchangeCollection', {
          id: -1,
          company_id: -1,
          collection_type_id: null,
          total_value: 0,
          input_date: yyyymmdd(new Date()),
          sales_product_id: null,
        });
      } else {
        this.$store.commit(
          'setManagementDataToExchangeCollection',
          this.lodash.clonedeep(this.filtered_collections[index]),
        );
      }
    },
    async setStartDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.end_date) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최후날짜(${this.end_date}) 이후의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.start_date;
        } else {
          this.$store.commit(
            'setStartDateToExchangeCollection',
            e.target.value,
          );
          this.SelectRow(-1);
          await this.FETCH_EXCHANGE_COLLECTION();
          await this.FETCH_WITH_PAYLOAD(
            'FETCH_SALES_ACCOUNT_DAY_ALL_TO_OUTPUT',
            {
              start: `1950-01-01`,
              end: this.end_date,
            },
            '매출 내역',
          );
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit(
          'setStartDateToExchangeCollection',
          yyyymmdd(new Date()),
        );
      }
    },
    async setEndDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.start_date) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최초날짜(${this.start_date}) 이전의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.end_date;
        } else {
          this.$store.commit('setEndDateToExchangeCollection', e.target.value);
          this.SelectRow(-1);
          await this.FETCH_EXCHANGE_COLLECTION();
          await this.FETCH_WITH_PAYLOAD(
            'FETCH_SALES_ACCOUNT_DAY_ALL_TO_OUTPUT',
            {
              start: `1950-01-01`,
              end: this.end_date,
            },
            '매출 내역',
          );
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
      }
    },
    setManagementCollectionType(e) {
      this.managementData.collection_type_id = e;
    },
    async FETCH_EXCHANGE_COLLECTION() {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_EXCHANGE_COLLECTION', {
          start: this.start_date,
          end: this.end_date,
        })
        .then(() => {})
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '로드 중 오류',
            '수금 리스트를 불러오는 중 오류발생',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    async submitForm() {
      if (this.checkValidTotalValue && this.checkValidType) {
        const payload = this.lodash.clonedeep(this.managementData);
        payload.total_value = this.$makeNumber(payload.total_value);

        this.showSpinner();
        if (this.selectedIndex != -1) {
          await this.$store
            .dispatch('UPDATE_EXCHANGE_COLLECTION', payload)
            .then(() => {
              this.openOneButtonModal(
                '수정 성공',
                '성공적으로 수정하였습니다.',
              );
              this.FETCH_EXCHANGE_COLLECTION();
              this.$store.commit('setManagementDataToExchangeCollection', {
                id: -1,
                company_id: -1,
                collection_type_id: null,
                total_value: 0,
                input_date: yyyymmdd(new Date()),
                sales_product_id: null,
              });
              this.SelectRow(-1);
            })
            .catch(error => {
              console.log(error);
              this.openOneButtonModal(
                '저장 중 에러',
                '수금 등록 저장 중 에러발생',
              );
            })
            .finally(() => {
              this.hideSpinner();
            });
        } else {
          await this.$store
            .dispatch('INSERT_EXCHANGE_COLLECTION', payload)
            .then(() => {
              this.openOneButtonModal(
                '등록 성공',
                '성공적으로 등록하였습니다.',
              );

              // 리스트 업데이트
              this.FETCH_EXCHANGE_COLLECTION();
              // 초기화
              this.$store.commit('setManagementDataToExchangeCollection', {
                id: -1,
                company_id: -1,
                collection_type_id: null,
                total_value: 0,
                input_date: yyyymmdd(new Date()),
                sales_product_id: null,
              });
            })
            .catch(error => {
              console.log(error);
              this.openOneButtonModal(
                '저장 중 에러',
                '수금 등록 저장 중 에러발생',
              );
            })
            .finally(() => {
              this.hideSpinner();
            });
        }
      } else {
        if (!this.checkValidTotalValue) {
          this.openOneButtonModal(
            '수금액 수정 오류',
            '수금액은 1원이상 입력해주세요.',
          );
        } else if (!this.checkValidType) {
          this.openOneButtonModal(
            '수정 오류',
            '수금 구분을 반드시 선택해 주세요.',
          );
        }
      }
    },
    async deleteCompany(arg1) {
      if (arg1 == undefined) return;
      this.showSpinner();
      //박스 삭제
      this.$store
        .dispatch('DELETE_EXCHANGE_COLLECTION', this.managementData.id)
        .then(() => {
          this.FETCH_EXCHANGE_COLLECTION();
          this.SelectRow(-1);
        })
        .catch(() => {
          this.openOneButtonModal('삭제 오류', '삭제 중 오류 발생.');
        })
        .finally(() => {
          this.hideSpinner();
          this.CloseModal();
        });

      this.hideSpinner();
    },
    changeSelectric(type) {
      this.SelectRow(-1);
      this.$store.commit('setSearchTypeToExchangeCollection', type);
    },
  },
  async created() {
    if (this.start_date == null) {
      const today = new Date();
      this.$store.commit('setEndDateToExchangeCollection', yyyymmdd(today));
      const lastMonth = new Date();
      lastMonth.setMonth(lastMonth.getMonth() - 1);
      this.$store.commit(
        'setStartDateToExchangeCollection',
        yyyymmdd(lastMonth),
      );
    }

    if (this.collection_options.length < 2) {
      await this.FETCH('FETCH_COLLECTION_TYPE', '수금구분');
    }
    if (this.sales_type.length < 1) {
      await this.FETCH('FETCH_SALES_TYPE', '매출유형');
    }
    if (this.products.length < 1) {
      this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처 리스트');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    await this.FETCH_WITH_PAYLOAD(
      'FETCH_SALES_ACCOUNT_DAY_ALL_TO_OUTPUT',
      {
        start: `1950-01-01`,
        end: this.end_date,
      },
      '매출 내역',
    );
    await this.FETCH_EXCHANGE_COLLECTION();
    if (this.managementData.input_date == '') {
      this.managementData.input_date = yyyymmdd(new Date());
    }
  },
};
</script>

<style></style>
