<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <div>
          <span class="option_txt">조회 기간</span>
          <div>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="start_date"
                @change="setStartDate($event)"
              />
            </div>
            <p>~</p>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="end_date"
                @change="setEndDate($event)"
              />
            </div>
          </div>
        </div>
        <div>
          <div class="input_search">
            <input
              type="text"
              placeholder="구매처 검색"
              :value="search_value"
              @input="typing($event)"
            />
            <button>
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
        <div>
          <span class="option_txt">지급 구분</span>

          <div class="input_text">
            <my-local-selectric
              v-if="showSelectric"
              :id="'payment_selectric'"
              :watch="search_type"
              :options="payment_options"
              @changeValue="changeSelectric($event)"
            >
            </my-local-selectric>
          </div>
        </div>
      </div>
      <h3>지급 현황</h3>
      <h6>조회수 : {{ filtered_payments.length }}건</h6>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl" :class="{ manage: managementMode }">
          <colgroup>
            <col v-for="(n, index) in managementMode ? 5 : 4" :key="index" />
          </colgroup>
          <thead>
            <tr>
              <th>지급일</th>
              <th>구매처명</th>
              <th>구매항목</th>
              <th>수량(단위)</th>
              <th>지급액</th>
              <th>지급구분</th>
              <th v-show="managementMode">삭제</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in filtered_payments"
              :key="item.id"
              @click="selectRow(index)"
              :class="selectedIndex == index ? 'active' : ''"
            >
              <td>{{ item.input_date }}</td>
              <td class="text_left">
                {{ findInfoFromId(companys, item.company_id).name }}
              </td>
              <td>
                {{
                  findInfoFromId(
                    materials,
                    findInfoFromId(filteredPurchase, item.purchase_material_id)
                      .material_id,
                  ).name
                }}
              </td>
              <td>
                {{
                  findInfoFromId(filteredPurchase, item.purchase_material_id)
                    .quantity
                }}
                {{
                  findInfoFromId(filteredPurchase, item.purchase_material_id)
                    .quantity != null
                    ? `(${
                        findInfoFromId(
                          units,
                          findInfoFromId(
                            filteredPurchase,
                            item.purchase_material_id,
                          ).unit_id,
                        ).name
                      })`
                    : ''
                }}
              </td>
              <td class="text_right">
                &#8361; {{ mx_makeComma(item.total_value) }}
              </td>
              <td>
                {{ findInfoFromId(payment_types, item.payment_type_id).name }}
              </td>
              <td v-show="managementMode">
                <button class="tbl_delete_btn" @click="ShowModal()">
                  delete
                </button>
              </td>
            </tr>
            <tr
              @click="selectRow(-1)"
              v-show="managementMode"
              :class="selectedIndex == -1 ? 'new' : 'new_disable'"
              class="newBtn"
            >
              <td colspan="7" @click="selectRow(-1)">
                + 신규 등록
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="aside_management_mode">
      <div class="head">
        <h5>세부정보</h5>
      </div>
      <div class="form" @submit.prevent>
        <form>
          <div class="input_text">
            <label class="require">구매처명</label>
            <div class="input_search">
              <input
                type="text"
                placeholder="구매처 검색"
                :readonly="managementData.company_id != null"
                :value="
                  findInfoFromId(companys, managementData.company_id).name
                "
                :disabled="!managementMode"
              />
              <button v-show="managementMode">
                <i class="fa fa-search" @click="showCompanySearch = true"></i>
              </button>
            </div>
          </div>
          <div class="input_text">
            <label>지급일</label>
            <input
              type="date"
              placeholder="YYYY-MM-DD"
              v-model="managementData.input_date"
              :disabled="!managementMode"
              :readonly="!managementMode"
            />
          </div>
          <div class="input_text">
            <label>지급액</label>
            <input
              type="text"
              placeholder=""
              :value="$makeComma(managementData.total_value)"
              inputmode="decimal"
              @input="$inputNumberInt($event, managementData, 'total_value')"
              :disabled="!managementMode"
            />
          </div>
          <div>
            <div class="input_text">
              <label>구매항목</label>
              <div class="input_search">
                <input
                  class="position"
                  type="text"
                  :placeholder="selectedIndex == -1 ? '구매항목 검색' : ''"
                  readonly
                  :value="
                    managementData.purchase_material_id != null
                      ? `${
                          findInfoFromId(
                            filteredPurchase,
                            managementData.purchase_material_id,
                          ).create_time != undefined
                            ? findInfoFromId(
                                filteredPurchase,
                                managementData.purchase_material_id,
                              ).create_time.substr(0, 10)
                            : ''
                        } / ${
                          findInfoFromId(
                            materials,
                            findInfoFromId(
                              filteredPurchase,
                              managementData.purchase_material_id,
                            ).material_id,
                          ).name
                        } / ${
                          findInfoFromId(
                            filteredPurchase,
                            managementData.purchase_material_id,
                          ).quantity
                        } ${
                          findInfoFromId(
                            units,
                            findInfoFromId(
                              filteredPurchase,
                              managementData.purchase_material_id,
                            ).unit_id,
                          ).name
                        } `
                      : ''
                  "
                  :disabled="!managementMode"
                />
                <div v-show="managementMode" v-if="isPermissionOn">
                  <button>
                    <i
                      class="fa fa-search"
                      @click="showPurchasesItemSearch = true"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="input_text">
            <label>지급구분</label>
            <div>
              <my-local-selectric
                v-if="showSelectric"
                :id="'payment_selectric'"
                :watch="managementData.payment_type_id"
                :options="payment_options"
                @changeValue="setManagementExpenseType($event)"
                :class="managementMode ? '' : 'disabled'"
              >
              </my-local-selectric>
            </div>
          </div>
          <div class="btn_wrap" v-show="managementMode" v-if="isPermissionOn">
            <button class="btn_sub2" @click="submitForm()">
              {{ selectedIndex == -1 ? '등록' : '수정' }}
            </button>
          </div>
        </form>
      </div>
    </div>
    <company-search
      v-if="showCompanySearch"
      :search_type="2"
      @onclose="showCompanySearch = false"
      @onselect="pushManagementCompany($event)"
    ></company-search>
    <two-button-modal
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      :index="my_modal_index"
      @onclose="CloseModal"
      @OnYesClick="deletePayment()"
    ></two-button-modal>
    <SalesItemSearch
      v-if="showPurchasesItemSearch"
      :search_type="2"
      @onclose="showPurchasesItemSearch = false"
      @onselect="pushMaterialsToList($event)"
    />
  </div>
</template>

<script>
import MyLocalSelectric from '@/layouts/components/MyLocalSelectric';
// import MySelectric from '@/layouts/components/MySelectric';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import FavoriteMixin from '@/mixins/favorite';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
import { yyyymmdd } from '@/utils/func';
import { mapGetters } from 'vuex';
import fetchMixin from '@/mixins/fetch';
import price from '@/mixins/price';

import CompanySearch from '@/layouts/components/search-popup/CompanySearch';
import SalesItemSearch from '@/layouts/components/search-popup/SalesItemSearch';

export default {
  mixins: [ModalMixin, fetchMixin, SpinnerMixin, FavoriteMixin, price],
  data() {
    return {
      search_value: '',
      showSelectric: false,
      showCompanySearch: false,
      showPurchasesItemSearch: false,
      //delete modal
      my_modal_title: '',
      my_modal_content: '',
      my_modal_index: -1,
      my_isModalOpen: false,
    };
  },
  components: {
    TwoButtonModal,
    CompanySearch,
    MyLocalSelectric,
    // MySelectric,
    SalesItemSearch,
  },
  computed: {
    ...mapGetters({
      purchase_account: 'getPurchaseAccountFromInput',
      managementMode: 'getManagementModeFromExchangeManagementPage',
      managementData: 'getManagementDataFromExchangePayment',
      payment_options: 'getPaymentsForSelectricAll',
      search_type: 'getSearchTypeFromExchangePayment',
      selectedIndex: 'getSelectedIndexFromExchangePayment',
      payments: 'getPayments',
      payment_types: 'getPaymentTypes',
      start_date: 'getStartDateFromExchangePayment',
      end_date: 'getEndDateFromExchangePayment',
      companys: 'getCompany',
      materials: 'getMaterial',
      units: 'getUnitCodes',
    }),
    checkValid() {
      return this.managementData.payment_type_id == null ? false : true;
    },
    checkValidTotalValue() {
      if (
        this.managementData.total_value == undefined ||
        this.managementData.total_value == null ||
        String(this.managementData.total_value).trim() == '0' ||
        String(this.managementData.total_value).trim() == ''
      ) {
        return false;
      } else return true;
    },
    filtered_payments() {
      const Hangul = require('hangul-js');
      return this.payments
        .filter(
          x =>
            x.payment_type_id == this.search_type || this.search_type == null,
        )
        .filter(
          x =>
            this.findInfoFromId(this.companys, x.company_id).name.includes(
              this.search_value,
            ) ||
            Hangul.d(
              this.findInfoFromId(this.companys, x.company_id).name,
              true,
            )
              .map(x => x[0])
              .join('')
              .includes(this.search_value),
        );
    },
    filteredPurchase() {
      if (
        this.purchase_account != undefined &&
        this.purchase_account.length > 0
      ) {
        const clone = this.lodash
          .clonedeep(this.purchase_account)
          .map(x =>
            x.purchase_material_list.map(y => ({
              ...y,
              create_time: x.create_time,
            })),
          )
          .reduce((a, b) => a.concat(b), []);
        return clone;
      } else {
        return [];
      }
    },
  },
  methods: {
    async setStartDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.end_date) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최후날짜(${this.end_date}) 이후의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.start_date;
        } else {
          this.$store.commit('setStartDateToExchangePayment', e.target.value);
          this.selectRow(-1);
          await this.FETCH_WITH_PAYLOAD(
            'FETCH_PAYMENT',
            {
              start: this.start_date,
              end: this.end_date,
            },
            '지급 리스트',
          );
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit(
          'setStartDateToExchangePayment',
          yyyymmdd(new Date()),
        );
      }
    },
    async setEndDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.start_date) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최초날짜(${this.start_date}) 이전의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.end_date;
        } else {
          this.$store.commit('setEndDateToExchangePayment', e.target.value);
          this.selectRow(-1);
          await this.FETCH_WITH_PAYLOAD(
            'FETCH_PAYMENT',
            {
              start: this.start_date,
              end: this.end_date,
            },
            '지급 리스트',
          ); //날짜
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setEndDateToExchangePayment', yyyymmdd(new Date()));
      }
    },
    setManagementExpenseType(e) {
      this.managementData.payment_type_id = e;
    },
    changeSelectric(type) {
      this.selectRow(-1);
      this.$store.commit('setSearchTypeToExchangePayment', type);
    },
    ShowModal(index) {
      this.my_modal_index = index;
      this.my_modal_title = '경고';
      this.my_modal_content = '정말로 삭제하시겠습니까?';
      this.my_isModalOpen = true;
    },
    CloseModal() {
      this.my_modal_index = -1;
      this.my_modal_title = '';
      this.my_modal_content = '';
      this.my_isModalOpen = false;
    },
    typing(e) {
      this.search_value = e.target.value.trim();
      this.selectRow(-1);
    },
    pushManagementCompany(arg) {
      this.managementData.company_id = arg.id;
      this.showCompanySearch = false;
    },
    pushMaterialsToList(arg) {
      if (this.showPurchasesItemSearch) {
        this.showPurchasesItemSearch = false;
      }
      this.managementData.purchase_material_id = arg.id;
    },
    selectRow(index) {
      this.$store.commit('setSelectedIndexToExchangePayment', index);
      if (index != -1) {
        this.$store.commit(
          'setManagementDataToExchangePayment',
          this.lodash.clonedeep(this.filtered_payments[index]),
        );
      } else {
        this.$store.commit('setManagementDataToExchangePayment', {
          id: -1,
          company_id: null,
          payment_type_id: null,
          total_value: null,
          input_date: yyyymmdd(new Date()),
          purchase_material_id: null,
        });
      }
    },
    async deletePayment() {
      this.showSpinner();
      await this.$store
        .dispatch('DELETE_PAYMENTS', this.payments[this.selectedIndex].id)
        .then(() => {
          this.$store.commit('setSelectedIndexToExchangePayment', -1);
          this.$store.commit('setManagementDataToExchangePayment', {
            id: -1,
            company_id: null,
            payment_type_id: null,
            total_value: null,
            input_date: yyyymmdd(new Date()),
            purchase_material_id: null,
          });
          this.FETCH_WITH_PAYLOAD('FETCH_PAYMENT', {
            start: this.start_date,
            end: this.end_date,
          });
        })
        .catch(() => {
          this.openOneButtonModal('삭제 중 오류', '삭제 중 오류 발생');
        })
        .finally(() => {
          this.CloseModal();
          this.hideSpinner();
        });
    },
    async submitForm() {
      if (this.checkValid && this.checkValidTotalValue) {
        const payload = this.lodash.clonedeep(this.managementData);
        payload.total_value = this.$makeNumber(payload.total_value);

        this.showSpinner();
        if (this.selectedIndex != -1) {
          await this.$store
            .dispatch('UPDATE_PAYMENTS', payload)
            .then(() => {
              this.openOneButtonModal(
                '수정 성공',
                '성공적으로 수정하였습니다.',
              );
              this.FETCH_WITH_PAYLOAD(
                'FETCH_PAYMENT',
                {
                  start: this.start_date,
                  end: this.end_date,
                },
                '지급',
              );
              this.selectRow(-1);
            })
            .catch(() => {
              this.openOneButtonModal('수정 중 오류', '수정 중 오류 발생');
            })
            .finally(() => {
              this.hideSpinner();
            });
        } else {
          await this.$store
            .dispatch('INSERT_PAYMENTS', payload)
            .then(() => {
              this.openOneButtonModal(
                '등록 성공',
                '성공적으로 등록하였습니다.',
              );

              this.FETCH_WITH_PAYLOAD(
                'FETCH_PAYMENT',
                {
                  start: this.start_date,
                  end: this.end_date,
                },
                '지급 리스트',
              );
              this.$store.commit('setManagementDataToExchangePayment', {
                id: -1,
                company_id: null,
                payment_type_id: null,
                total_value: null,
                input_date: yyyymmdd(new Date()),
                purchase_material_id: null,
              });
            })
            .catch(() => {
              this.openOneButtonModal('등록 실패', '등록에 실패하였습니다.');
            })
            .finally(() => {
              this.hideSpinner();
            });
        }
      } else {
        if (!this.checkValid) {
          this.openOneButtonModal(
            '수정 불가',
            '지급 구분을 반드시 선택해 주세요.',
          );
        } else if (!this.checkValidTotalValue) {
          this.openOneButtonModal(
            '지급액 수정 오류',
            '지급액은 1원이상 입력해주세요.',
          );
        }
      }
    },
  },
  async created() {
    if (this.start_date == null) {
      const today = new Date();
      this.$store.commit('setEndDateToExchangePayment', yyyymmdd(today));
      const lastMonth = new Date();
      lastMonth.setMonth(lastMonth.getMonth() - 1);
      this.$store.commit('setStartDateToExchangePayment', yyyymmdd(lastMonth));
    }

    if (this.payment_options.length < 2) {
      await this.FETCH('FETCH_PAYMENTS_TYPE', '지급구분');
    }
    if (this.companys.length == 0) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.materials.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '원자재');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    await this.FETCH_WITH_PAYLOAD(
      'FETCH_PAYMENT',
      {
        start: this.start_date,
        end: this.end_date,
      },
      '지급 리스트',
    ); //날짜
    await this.FETCH_WITH_PAYLOAD(
      'FETCH_PURCHASE_ACCOUNT_DAY_ALL',
      {
        start: '1950-01-01',
        end: this.end_date,
      },
      '구매 내역',
    );
    if (this.managementData.input_date == '') {
      this.managementData.input_date = yyyymmdd(new Date());
    }
    this.showSelectric = true;
  },
};
</script>

<style></style>
