<template>
  <div>
    <div class="modalPopup" id="company_search_modal">
      <div class="modal_header">
        <h3 class="title">판매항목 검색</h3>
        <button class="modal_close" @click="$emit('onclose')"></button>
      </div>
      <div class="modal_body">
        <div class="modal_search_opt"></div>
        <div class="mes_tbl_wrap">
          <table class="mes_tbl">
            <thead>
              <tr>
                <th>{{ search_type == 1 ? `출고일` : `입고일` }}</th>
                <th>판매항목명</th>
                <th>수량</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in search_type == 1
                  ? filtered_sales
                  : filteredPurchase"
                :key="item.id"
                @click="selectedIndex = index"
                @dblclick="submit()"
                :class="selectedIndex == index ? 'active' : ''"
              >
                <td>{{ item.create_time | formatDateNoHours }}</td>
                <td>
                  {{
                    search_type == 1
                      ? findInfoFromId(products, item.product_id).name
                      : findInfoFromId(materials, item.material_id).name
                  }}
                </td>
                <td>
                  {{ item.quantity }}
                  {{
                    item.quantity != null
                      ? `(${findInfoFromId(units, item.unit_id).name})`
                      : ''
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal_footer">
        <div class="warning" v-show="error_text != ''">
          {{ error_text }}
        </div>
        <button type="button" class="btn_sub2" @click="submit()">
          선택
        </button>
      </div>
    </div>
    <div class="modal_layer" @click="$emit('onclose')"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SpinnerMixin from '@/mixins/spinner';
import ModalMixin from '@/mixins/modal';
import FetchMixin from '@/mixins/fetch';
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';
export default {
  mixins: [SpinnerMixin, ModalMixin, FetchMixin, DRAG_MODAL_MIXIN],
  props: {
    search_type: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      error_text: '',
      selectedIndex: -1,
      search_value: '',
    };
  },
  computed: {
    ...mapGetters({
      start_date: 'getStartDateFromExchangeCollection',
      end_date: 'getEndDateFromExchangeCollection',
      newSalesSrc: 'getNewSalesFromOutput',
      products: 'getProduct',
      units: 'getUnitCodes',
      materials: 'getMaterial',
      purchase_account: 'getPurchaseAccountFromInput',
      sales_type: 'getSalesType',
    }),
    filteredPurchase() {
      if (
        this.purchase_account != undefined &&
        this.purchase_account.length > 0
      ) {
        const clone = this.lodash
          .clonedeep(this.purchase_account)
          .map(x =>
            x.purchase_material_list.map(y => ({
              ...y,
              create_time: x.create_time,
            })),
          )
          .reduce((a, b) => a.concat(b), [])
          .filter(x => x.total_cost > 0 && x.payment_value == 0);
        return clone;
      } else {
        return [];
      }
    },
    filtered_sales() {
      if (this.newSalesSrc != undefined && this.newSalesSrc.length > 0) {
        let clone = this.lodash
          .clonedeep(this.newSalesSrc)
          .filter(
            x =>
              !this.findInfoFromId(
                this.sales_type,
                x.sales.sales_type_id,
              ).detail.includes('return'),
          )
          .map(x =>
            x.sales_product_list.map(y => ({
              ...y,
              create_time: x.create_time,
            })),
          )
          .reduce((a, b) => a.concat(b), [])
          .filter(x => x.total_cost > 0 && x.collect_value == 0);
        return clone;
      } else {
        return [];
      }
    },
  },
  methods: {
    submit() {
      if (this.selectedIndex == undefined || this.selectedIndex < 0) {
        this.error_text = `테이블의 행을 클릭하여 수주를 선택하여주십시오.`;
        return;
      }
      this.search_type == 1
        ? this.$emit('onselect', this.filtered_sales[this.selectedIndex])
        : this.$emit('onselect', this.filteredPurchase[this.selectedIndex]);
    },
  },
  async created() {
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.materials.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '원자재');
    }

    await this.FETCH_WITH_PAYLOAD(
      'FETCH_SALES_ACCOUNT_DAY_ALL_TO_OUTPUT',
      {
        start: `1950-01-01`,
        end: '2500-01-01',
      },
      '매출 내역',
    );
    await this.FETCH_WITH_PAYLOAD(
      'FETCH_PURCHASE_ACCOUNT_DAY_ALL',
      {
        start: '1950-01-01',
        end: '2500-01-01',
      },
      '구매 내역',
    );
  },
};
</script>

<style></style>
